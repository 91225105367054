import React from "react";
import styled from 'styled-components'
import Img from 'gatsby-image'

const ImageCard = ( { image = 'https://placehold.it/800x800', name = 'Place', title } ) => {

    const ImageContainers = styled.div({
        display: 'block',
        margin: '4px',
        flex: 1
    })

    const Image = styled(Img)`
        width: 100%;
        height: auto;
        border-radius: 16px;
        display: block;
    `

    const Name = styled.span`
        font-size: 16px;
        color: #666;
        font-family: 'Source Serif Pro', serif;
        display: block;
        line-height: 24px;
        margin-top: 12px;
        margin-bottom: 4px;
    `

    const PersonName = styled.span`
        font-size: 16px;
        color: #323232;
        font-family: 'Lato', sans-serif;
        display: block;
        font-weight: bold;
        line-height: 24px;
        margin-top: 12px;
        margin-bottom: 4px;
    `

    const Role = styled.span`
        font-size: 14px;
        color: #666;
        font-family: 'Source Serif Pro', serif;
        display: block;
        line-height: 14px;
        margin-bottom: 8px;
        
    `

    return (
        <ImageContainers>
            {
                title ?
                <>
                    <Image fluid={image} />
                    <PersonName>{ name }</PersonName>
                    <Role>{ title }</Role>
                </>

                :

                <>
                    <Image fluid={image} />
                    <Name>{ name }</Name>
                </>

            }
        </ImageContainers>
    )
}

export default ImageCard