import React from "react";
import styled from 'styled-components'

const ImageContainer  = ( { children, style  } ) => {

    const Container = styled.div`
        display: flex;
        width: calc(100% - 24px);
        margin: auto;
    `

    return (
        <Container style={style}>
            {children}
        </Container>
    )
}

export default ImageContainer