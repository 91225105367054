import React from "react";

import styled from 'styled-components'

import { useStaticQuery, graphql } from "gatsby"

import SEO from "../components/seo"
import Layout from "../components/layout"
import TextBlock from '../components/textBlock'
import Hero from '../components/hero'
import SectionTitle from '../components/sectionTitle'
import ImageCard from '../components/imageCard'
import ImageContainer from '../components/imageContainer'

const WeddingPartyPage = () => {
    const data = useStaticQuery(graphql`
        query {
            hero: file(relativePath: { eq: "Engagement-34.jpg" }) {
                childImageSharp {
                fluid(maxWidth: 1440) {
                    ...GatsbyImageSharpFluid
                }
                }
            }
        jaclyn: file(relativePath: { eq: "Jaclyn.jpg" }) {
            childImageSharp {
            fluid(maxWidth: 1440) {
                ...GatsbyImageSharpFluid
            }
            }
        }
        michelle: file(relativePath: { eq: "Michelle.jpg" }) {
            childImageSharp {
            fluid(maxWidth: 1440) {
                ...GatsbyImageSharpFluid
            }
            }
        }
        alexis: file(relativePath: { eq: "Alexis.jpg" }) {
            childImageSharp {
            fluid(maxWidth: 1440) {
                ...GatsbyImageSharpFluid
            }
            }
        }
        courtney: file(relativePath: { eq: "Courtney.jpg" }) {
            childImageSharp {
            fluid(maxWidth: 1440) {
                ...GatsbyImageSharpFluid
            }
            }
        }
        fish: file(relativePath: { eq: "DasFisch.jpg" }) {
            childImageSharp {
            fluid(maxWidth: 1440) {
                ...GatsbyImageSharpFluid
            }
            }
        }
        hyde: file(relativePath: { eq: "Hyde.jpg" }) {
            childImageSharp {
            fluid(maxWidth: 1440) {
                ...GatsbyImageSharpFluid
            }
            }
        }
        julio: file(relativePath: { eq: "image0.jpeg" }) {
            childImageSharp {
            fluid(maxWidth: 1440) {
                ...GatsbyImageSharpFluid
            }
            }
        }
        kozin: file(relativePath: { eq: "kozn.jpg" }) {
            childImageSharp {
            fluid(maxWidth: 1440) {
                ...GatsbyImageSharpFluid
            }
            }
        }

        rick: file(relativePath: { eq: "Rick.jpg" }) {
            childImageSharp {
            fluid(maxWidth: 1440) {
                ...GatsbyImageSharpFluid
            }
            }
        }

        cindy: file(relativePath: { eq: "Cindy.jpg" }) {
            childImageSharp {
            fluid(maxWidth: 1440) {
                ...GatsbyImageSharpFluid
            }
            }
        }

        scott: file(relativePath: { eq: "Scott.jpg" }) {
            childImageSharp {
            fluid(maxWidth: 1440) {
                ...GatsbyImageSharpFluid
            }
            }
        }

        joanne: file(relativePath: { eq: "JoAnne.jpg" }) {
            childImageSharp {
            fluid(maxWidth: 1440) {
                ...GatsbyImageSharpFluid
            }
            }
        }
        becca: file(relativePath: { eq: "Becca.jpg" }) {
            childImageSharp {
            fluid(maxWidth: 1440) {
                ...GatsbyImageSharpFluid
            }
            }
        }
        brian: file(relativePath: { eq: "Brian.jpg" }) {
            childImageSharp {
            fluid(maxWidth: 1440) {
                ...GatsbyImageSharpFluid
            }
            }
        }
    }
    `)

    const HeaderContainer = styled.div ({
        backgroundColor: '#fff',
        padding: '24px',
        boxSizing: 'border-box',
        width: 'calc(100% - 32px)',
        display: 'block',
        margin: 'auto',
        'box-shadow': '0px 0px 12px 4px rgba(0, 0, 0, 0.2)',
        borderRadius: '16px',
        marginTop: "-24px",
        position: 'relative',
        'z-index': '10',
      })
    
  return ( 
    <div>
      <Layout>
        <SEO title="Wedding Party | Reinhart Mitton" />
        <Hero file={data.hero.childImageSharp.fluid} style={{marginTop: '50px'}}/>
        <HeaderContainer>
            <TextBlock
                title="Wedding Party"
                subTitle="The Dream Team"
            />
        </HeaderContainer>
        <SectionTitle title="Bridesmaids" style={{ margin: "24px 16px", display: "block" }}/>
            <ImageContainer>
                <ImageCard name="Michelle Reinhart" image={data.michelle.childImageSharp.fluid} title="Maid of Honor"/>
                <ImageCard name="Jaclyn Reinhart" image={data.jaclyn.childImageSharp.fluid} title="Maid of Honor"/>
            </ImageContainer>
            <ImageContainer>
                <ImageCard name="Alexis Mitton" image={data.alexis.childImageSharp.fluid} title="Bridesmaid"/>
                <ImageCard name="Courtney Moses" image={data.courtney.childImageSharp.fluid} title="Bridesmaid"/>
            </ImageContainer>
            <ImageContainer>
                <ImageCard name="Becca Siegrist" image={data.becca.childImageSharp.fluid} title="Bridesmaid"/>
                <ImageCard name=" "  title=" "/>
            </ImageContainer>

            <SectionTitle title="Groomsmen" style={{ margin: "24px 16px", display: "block" }}/>

            <ImageContainer>
                <ImageCard name="Michael Fischer" image={data.fish.childImageSharp.fluid} title="Best Man"/>
                <ImageCard name="Shaun Hyde" image={data.hyde.childImageSharp.fluid} title="Groomsman"/>
            </ImageContainer>
            <ImageContainer>
                <ImageCard name="Julio Martinez" image={data.julio.childImageSharp.fluid} title="Groomsman"/>
                <ImageCard name="Alex Kozin" image={data.kozin.childImageSharp.fluid} title="Groomsman"/>
            </ImageContainer>
            <ImageContainer>
                <ImageCard name="Brian Hunn" image={data.brian.childImageSharp.fluid} title="Groomsman"/>
                <ImageCard name=" "  title=" "/>
            </ImageContainer>

            <SectionTitle title="Parents" style={{ margin: "24px 16px", display: "block" }}/>

            <ImageContainer>
                <ImageCard name="Rick Reinhart" image={data.rick.childImageSharp.fluid} title="Father of the Bride"/>
                <ImageCard name="Cindy Reinhart" image={data.cindy.childImageSharp.fluid} title="Mother of the Bride"/>
            </ImageContainer>
            <ImageContainer>
                <ImageCard name="Scott Mitton" image={data.scott.childImageSharp.fluid} title="Father of the Groom"/>
                <ImageCard name="JoAnne Mitton" image={data.joanne.childImageSharp.fluid} title="Mother of the Groom"/>
            </ImageContainer>

        </Layout>
    </div>     
  )
}

export default WeddingPartyPage