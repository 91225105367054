import React from 'react'
import styled from 'styled-components'

const SectionTitle = ( { title, subTitle, children, style } ) => {
    const Title = styled.h4`
        font-family: 'Lato';
        font-size: 18px;
        font-weight: 900;
        line-height: 26px;
        color: #323232;
        text-align: left;
        margin: 0;
    `

    return (
        <>  
            <Title style={style}>{ title}</Title>
        </>
    )
}

export default SectionTitle